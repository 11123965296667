<div>
  <h2 class="heading-title--small">{{ header() }}</h2>
  <div class="flex flex-col gap-5">
    @for (variant of variants; track $index) {
      <div class="flex gap-3">
        <button
          flkIconButton
          [icon]="['fas', 'clone']"
          [tooltip]="buttonText"
          [appearance]="appearance()"
          [variant]="variant"></button>
        <button
          flkIconButton
          [disabled]="true"
          [icon]="['fas', 'clone']"
          [tooltip]="buttonText"
          [appearance]="appearance()"
          [variant]="variant"></button>
        <button
          flkIconButton
          [loading]="true"
          [icon]="['fas', 'clone']"
          [tooltip]="buttonText"
          [appearance]="appearance()"
          [variant]="variant"></button>
        <button flkButton [appearance]="appearance()" [variant]="variant">
          {{ buttonText }}
        </button>
        <button
          flkButton
          [icon]="['fas', 'clone']"
          [appearance]="appearance()"
          [variant]="variant">
          {{ buttonText }}
        </button>
        <button
          flkButton
          [appearance]="appearance()"
          [variant]="variant"
          [disabled]="true">
          {{ buttonText }}
        </button>
        <button
          flkButton
          [disabled]="true"
          [icon]="['fas', 'clone']"
          [appearance]="appearance()"
          [variant]="variant">
          {{ buttonText }}
        </button>
        <button
          flkButton
          [appearance]="appearance()"
          [variant]="variant"
          [loading]="true">
          {{ buttonText }}
        </button>
      </div>
    }
  </div>
</div>
