import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from '@environment/environment';
import { provideAppInterceptors } from './app.route-providers';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideAuthServices } from '@core/states/auth/provide-auth-services';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAuthServices(),
    provideStore(),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    provideCharts(withDefaultRegisterables()),
    provideAppInterceptors(),
    importProvidersFrom(MatDialogModule),
  ],
};
