import { Component, computed, input, output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'flk-pagination-page-option',
  imports: [NgClass],
  styleUrls: ['../pagination.css'],
  template: `
    <div
      (click)="onChangePage(page())"
      class="relative inline-flex items-center rounded border-2 px-3 py-1 text-dune-500"
      [ngClass]="getClass()">
      <span class="not-selectable text-current">
        {{ label() }}
      </span>
    </div>
  `,
})
export class PaginationPageOptionComponent {
  /** INPUTS **/

  /** page represented by the component **/
  page = input.required<number>();

  /** label shown **/
  label = input.required<string>();

  /** currently chosen page **/
  currentPage = input.required<number>();

  /** if the component is clickable **/
  disabled = input<boolean>(false);

  /** if the component is the last if the options list **/
  last = input<boolean>(false);

  /** OUTPUTS **/

  /** the page number chosen through a click on the component **/
  pageChosen = output<number>();

  getClass = computed(() => {
    return {
      ['border-greenish-500']: this.currentPage() === this.page(),
      ['border-transparent']: this.currentPage() !== this.page(),
      ['cursor-pointer hover:text-greenish-500 dark:hover:text-greenish-500]]]']:
        this.currentPage() !== this.page() && !this.disabled(),
      ['not-selectable']: this.currentPage() === this.page() || this.disabled(),
      ['mr-1']: !this.last(),
    };
  });

  onChangePage(page: number): void {
    if (this.page() !== -1 && !this.disabled()) {
      this.pageChosen.emit(page);
    }
  }
}
