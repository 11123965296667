import { Component } from '@angular/core';
import { BadgeComponent } from '@flowkit/badge';

@Component({
    selector: 'app-ui-badges',
    imports: [BadgeComponent],
    template: `
    <div>
      <h1 class="heading-title">Badges</h1>
      <div class="flex gap-10">
        <div class="flex flex-col gap-5">
          <flk-badge [label]="'Foo'" />
          <flk-badge [label]="'Foo'" [variant]="'accent'" />
        </div>
        <div class="flex flex-col gap-5">
          <flk-badge [label]="'Foo'" [hideRemove]="true" />
          <flk-badge [label]="'Foo'" [variant]="'accent'" [hideRemove]="true" />
        </div>
      </div>
    </div>
  `
})
export class UIBadgesComponent {}
