import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@core/states/app.state';
import { selectHasRole } from '@core/states/auth/auth.selectors';
import { UserRole } from '@corezilla/api';

@Directive({
  standalone: true,
  selector: '[appHasRole]',
})
export class HasRoleDirective {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store: Store<AppState>
  ) {}

  @Input() set appHasRole(roles: UserRole[]) {
    const isAllowed = this.store.selectSignal(selectHasRole(roles || []))();

    if (isAllowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
