import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarItemStore } from '@core/components/sidebar/components/sidebar-item/sidebar-item.store';
import { SidebarItemCollapsedHoverComponent } from '@core/components/sidebar/components/sidebar-item/item/collapsed/sidebar-item-collapsed-hover.component';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { SidebarItemButtonComponent } from '@core/components/sidebar/components/sidebar-item/sidebar-item-button.component';
import { Subscription } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-sidebar-item-collapsed',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FontAwesomeModule,
    SidebarItemCollapsedHoverComponent,
    SidebarItemButtonComponent,
    MatTooltip,
  ],
  template: `
    <li class="my-0.5 flex w-full">
      <app-sidebar-item-button
        [matTooltip]="subPages() ? '' : mainLabel()"
        [matTooltipShowDelay]="DEBOUNCE_TIME"
        matTooltipClass="-ml-3"
        [matTooltipPosition]="'right'"
        [isActive]="routerLinkActive()"
        [highlightIcon]="routerLinkActive()"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="cancelOnHoverDuringBuffer()">
      </app-sidebar-item-button>

      <app-sidebar-item-collapsed-hover
        class="absolute"
        [ngClass]="{ invisible: !(hover() && subPages()) }"
        [hovering]="hover()"
        (mouseleave)="onMouseLeave()"
        (exitItem)="onMouseLeave()">
      </app-sidebar-item-collapsed-hover>
    </li>
  `,
})
export class SidebarItemCollapsedComponent implements OnInit, OnDestroy {
  icon = signal(this.sidebarItemStore.icon);
  mainPath = signal(this.sidebarItemStore.mainPath);
  mainLabel = signal(this.sidebarItemStore.mainLabel);
  subPages = signal(this.sidebarItemStore.subPages);
  routerLinkActive = signal<boolean>(false);
  hover = signal<boolean>(false);

  DEBOUNCE_TIME = 600;
  private timeout: NodeJS.Timeout;
  private subscription: Subscription;
  private hoverBufferActive = false;

  constructor(
    private sidebarItemStore: SidebarItemStore,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {
    if (this.subPages()) {
      this.subscription = this.sidebarService.routeChanged.subscribe(route => {
        this.routerLinkActive.set(
          this.subPages()!.some(page => page.path === route)
        );
      });
    } else {
      this.subscription = this.sidebarService.routeChanged.subscribe(route => {
        this.routerLinkActive.set(this.mainPath() === route);
      });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onMouseEnter() {
    this.hoverBufferActive = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.hover.set(true);
      this.hoverBufferActive = false;
    }, this.DEBOUNCE_TIME);
  }

  onMouseLeave() {
    clearTimeout(this.timeout);
    setTimeout(() => {
      this.hover.set(false);
    }, 0);
  }

  cancelOnHoverDuringBuffer() {
    if (this.hoverBufferActive) {
      this.onMouseLeave();
    }
  }
}
