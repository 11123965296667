import { Component, signal } from '@angular/core';
import { PaginationComponent } from '@flowkit/pagination/pagination.component';

@Component({
  selector: 'app-ui-pagination',
  imports: [PaginationComponent],
  template: `
    <div>
      <h1 class="heading-title">Pagination</h1>
      <div class="flex gap-10">
        <flk-pagination
          class="w-9/12"
          [totalCount]="totalCount()"
          [currentPage]="currentPage()"
          [pageSize]="pageSize()"
          [stepSize]="10"
          [stepCount]="10"
          [initialValue]="pageSize()"
          (changePage)="onChangePage($event)"
          (changePageSize)="updatePageSize($event)">
        </flk-pagination>
      </div>
    </div>
  `,
})
export class UIPaginationComponent {
  totalCount = signal(1000);
  pageSize = signal(20);
  currentPage = signal(1);

  onChangePage(page: number) {
    this.currentPage.set(page);
  }

  updatePageSize(pageSize: number) {
    this.pageSize.set(pageSize);
  }
}
