import { ApiError, AuthUser } from '@corezilla/api';
import { ViewState } from '@core/enums/view-state';
import { CrudState } from '@core/enums/crud-state';

export interface AuthState {
  /**
   * Current view state of the authenticated user.
   * **/
  viewState: ViewState;

  /**
   * Current crud state of the authenticated user.
   **/
  crudState: CrudState | undefined;

  /**
   * The authenticated user.
   * **/
  user: AuthUser | undefined;

  /**
   *
   * **/
  viewStateError: ApiError | undefined;

  /**
   *
   **/
  crudStateError: ApiError | undefined;
}

export const initialAuthState: AuthState = {
  viewState: ViewState.Initial,
  crudState: undefined,
  user: undefined,
  viewStateError: undefined,
  crudStateError: undefined,
};
