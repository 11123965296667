import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HasRoleDirective } from '@core/directives/has-role.directive';
import {
  FaIconComponent,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faBuildingShield,
  faChartLine,
  faCircleNodes,
  faCodeMerge,
  faFingerprint,
  faGaugeHigh,
  faGear,
  faGears,
  faImages,
  faLayerGroup,
  faList,
  faLock,
  faShuffle,
  faStethoscope,
  faTags,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { IsFeatureEnabledDirective } from '@core/feature-preview/directives/is-feature-enabled.directive';
import { SidebarToggleComponent } from '@core/components/sidebar/sidebar-toggle.component';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { SidebarItemComponent } from '@core/components/sidebar/components/sidebar-item/sidebar-item.component';
import { UserRole } from '@corezilla/api';

@Component({
  selector: 'app-sidebar-content',
  imports: [
    CommonModule,
    FaIconComponent,
    HasRoleDirective,
    IsFeatureEnabledDirective,
    SidebarToggleComponent,
    SidebarItemComponent,
  ],
  styles: [
    `
      :host {
        @apply flex flex-col shadow;
      }

      .container {
        @apply overflow-y-hidden pb-2.5 pt-2;
        @apply flex h-full flex-col;
        @apply border-r;
      }
    `,
  ],
  template: `
    <aside class="bg--default h-content w-full" aria-label="Sidebar">
      <div class="border--default container">
        <div
          class="flex flex-grow flex-col gap-y-6 overflow-y-auto font-medium">
          <div class="flex flex-col">
            <ul>
              <app-sidebar-item
                *appIsFeatureEnabled="'Dashboard'"
                [icon]="['fas', 'chart-line']"
                label="Dashboard"
                path="dashboard" />
              <app-sidebar-item
                *appIsFeatureEnabled="'Workflow'"
                [icon]="['fas', 'circle-nodes']"
                label="Workflows"
                path="workflows" />
              <app-sidebar-item
                [icon]="['fas', 'building-shield']"
                label="Access Hub"
                path="tenants" />
              <app-sidebar-item
                [icon]="['fas', 'code-merge']"
                label="Processes"
                path="process-definitions" />
              <app-sidebar-item
                *appIsFeatureEnabled="'DetectionDefinitions'"
                [icon]="['fas', 'fingerprint']"
                label="Triggers"
                path="detection-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'shuffle']"
                label="Runs"
                path="process-instances"
                [subPages]="[
                  { label: 'Processes', path: 'process-instances' },
                  { label: 'Triggers', path: 'detection-instances' },
                ]" />
              <app-sidebar-item
                [icon]="['fas', 'list']"
                label="Changelogs"
                path="global-changelogs" />
              <app-sidebar-item
                [icon]="['fas', 'tags']"
                label="Tags"
                path="tags" />
            </ul>
          </div>
          <div class="flex flex-col">
            <div
              class="text--backdrop flex gap-2 pl-6 text-sm uppercase leading-6"
              [class.visible]="!collapsed()"
              [class.invisible]="collapsed()">
              <span>{{ collapsed() ? 'S' : 'Settings' }}</span>
            </div>
            <ul>
              <app-sidebar-item
                [icon]="['fas', 'gears']"
                label="Application"
                path="not-found" />
              <app-sidebar-item
                [icon]="['fas', 'user-group']"
                label="Users"
                path="users" />
            </ul>
          </div>

          <div class="flex flex-col" *appHasRole="[Role.ROLE_SUPER_ADMIN]">
            <div
              class="text--backdrop flex gap-2 pl-6 text-sm uppercase leading-6"
              [class.visible]="!collapsed()"
              [class.invisible]="collapsed()">
              <span> {{ collapsed() ? 'S' : 'Staff' }} </span>
              <fa-icon
                [icon]="'lock'"
                class="mb-1 text-xs text-dune-600"></fa-icon>
            </div>
            <ul>
              <app-sidebar-item
                [icon]="['fas', 'gear']"
                label="System Configuration"
                path="application-configurations" />
              <app-sidebar-item
                [icon]="['fas', 'layer-group']"
                label="Components"
                path="component-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'stethoscope']"
                label="Trigger Types"
                path="detection-type-definitions" />
              <app-sidebar-item
                [icon]="['fas', 'images']"
                label="Assets"
                path="assets" />
              <app-sidebar-item
                [icon]="['fas', 'gauge-high']"
                label="Process Throttles"
                path="process-throttles" />
            </ul>
          </div>
        </div>
        <div
          class="flex w-full pt-4"
          [ngClass]="{
            'justify-center pl-0.5': collapsed(),
            'justify-end pr-4': !collapsed(),
          }">
          <app-sidebar-toggle />
        </div>
      </div>
    </aside>
  `,
})
export class SidebarContentComponent {
  /**
   * PROPERTIES
   */
  protected readonly Role = UserRole;
  collapsed = toSignal(this.sidebarService.sidebarCollapsed$);

  constructor(
    private library: FaIconLibrary,
    private sidebarService: SidebarService
  ) {
    this.registerIcons();
  }

  private registerIcons() {
    this.library.addIcons(
      faChartLine,
      faCircleNodes,
      faBuildingShield,
      faCodeMerge,
      faShuffle,
      faList,
      faTags,
      faGear,
      faGears,
      faUserGroup,
      faLock,
      faLayerGroup,
      faStethoscope,
      faImages,
      faGaugeHigh,
      faFingerprint
    );
  }
}
