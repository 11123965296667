import { Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface SubPage {
  label: string;
  path: string;
}

@Injectable()
export class SidebarItemStore {
  /**
   *
   * **/
  private _icon: IconProp;

  /**
   *
   * **/
  private _mainLabel: string;

  /**
   *
   * **/
  private _mainPath: string;

  /**
   *
   * **/
  private _subPages: SubPage[] | null;

  init(
    icon: IconProp,
    mainLabel: string,
    mainPath: string,
    subPages: SubPage[] | null
  ) {
    this._icon = icon;
    this._mainLabel = mainLabel;
    this._mainPath = mainPath;
    this._subPages = subPages;
  }

  get icon(): IconProp {
    return this._icon;
  }

  get mainLabel(): string {
    return this._mainLabel;
  }

  get mainPath(): string {
    return this._mainPath;
  }

  get subPages(): SubPage[] | null {
    return this._subPages;
  }
}

export function provideSidebarItemStore() {
  return [SidebarItemStore];
}
