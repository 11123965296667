import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Output,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-navbar-menu-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div
      class="flex cursor-pointer gap-2 rounded-lg p-2 hover:bg-neutral-100 hover:dark:bg-neutral-900"
      (click)="onClick()">
      @if (icon()) {
        <fa-icon [icon]="icon()!!"></fa-icon>
      }
      <span>{{ label() }}</span>
    </div>
  `,
    imports: [FaIconComponent]
})
export class NavbarComponent {
  /**
   * INPUTS
   */
  label = input.required<string>();
  icon = input<IconProp>();

  // Outputs
  @Output() clickEvent = new EventEmitter<boolean>();

  onClick() {
    this.clickEvent.emit();
  }
}
