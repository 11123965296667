import { inject } from '@angular/core';
import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthEffects } from '@core/states/auth/auth.effects';
import { LOGIN_PATH } from '@features/auth/auth.routes';

export const appGuard: CanActivateFn = () => {
  const router = inject(Router);
  const urlTree = router.createUrlTree([LOGIN_PATH]);
  const redirect = new RedirectCommand(urlTree);
  const authEffects = inject(AuthEffects);

  return authEffects.isLoggedIn$().pipe(
    map(isLoggedIn => (isLoggedIn ? true : redirect)),
    catchError(() => of(redirect))
  );
};
