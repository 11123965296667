import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { NavMenuComponent } from '@core/components/navbar/nav-menu/nav-menu.component';
import { ThemeSwitchComponent } from './theme-switch.component';
import { RouterLink } from '@angular/router';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeService } from '@core/services/theme.service';
import { Theme } from '@core/enums/theme';

@Component({
  selector: 'app-navbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BreadcrumbComponent,
    CommonModule,
    MatButtonModule,
    NavMenuComponent,
    ThemeSwitchComponent,
    RouterLink,
  ],
  styles: `
    :host {
      @apply relative sticky top-0 z-50 flex flex-col overflow-visible;
    }
  `,
  template: `
    <nav
      class="border--default flex h-14 items-center justify-between border-b bg-white px-4 dark:bg-neutral-950">
      <div class="flex" [class]="gap()">
        <a class="cursor-pointer" routerLink="/dashboard">
          <div class="group flex items-center">
            @if (collapsed()) {
              <img
                class="w-[2.1rem]"
                alt="Flowzilla"
                src="./assets/images/gorilla.svg" />
            } @else {
              <img class="w-52" alt="Flowzilla" [src]="image()" />
            }
          </div>
        </a>
        <div class="flex items-center">
          <app-breadcrumb />
        </div>
      </div>

      <div class="flex items-center gap-x-4">
        <app-theme-switch />
        <app-nav-menu class="self-center" />
      </div>
    </nav>
  `,
})
export class NavbarComponent {
  /**
   * PROPERTIES
   */
  collapsed = toSignal(this.sidebarService.sidebarCollapsed$);

  gap = computed(() => (this.collapsed() ? 'gap-3' : 'gap-14'));

  image = computed(() => {
    const file =
      this.themeService.theme() === Theme.DARK
        ? 'flowzilla-dark.svg'
        : 'flowzilla.svg';
    return `./assets/images/${file}`;
  });

  constructor(
    private sidebarService: SidebarService,
    private themeService: ThemeService
  ) {}
}
