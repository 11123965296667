import { Component } from '@angular/core';
import { UIButtonsComponent } from '@features/dev/ui-components/buttons/ui-buttons.component';
import { ThemeSwitchComponent } from '@core/components/navbar/theme-switch.component';
import { FormControl } from '@angular/forms';
import { UITogglesComponent } from '@features/dev/ui-components/toggle/ui-toggles.component';
import { UIBadgesComponent } from '@features/dev/ui-components/badges/ui-badges.component';
import { UIPaginationComponent } from '@features/dev/ui-components/pagination/ui-pagination.component';
import { UIAlertsComponent } from '@features/dev/ui-components/alerts/ui-alerts.component';

@Component({
  selector: 'app-ui-components',
  imports: [
    ThemeSwitchComponent,
    UIButtonsComponent,
    UITogglesComponent,
    UIBadgesComponent,
    UIPaginationComponent,
    UIAlertsComponent,
  ],
  template: `
    <div class="bg--default h-full overflow-y-scroll p-5">
      <div class="text-end">
        <app-theme-switch />
      </div>
      <div class="flex flex-col gap-20">
        <app-ui-buttons></app-ui-buttons>
        <app-ui-badges></app-ui-badges>
        <app-ui-toggles></app-ui-toggles>
        <app-ui-pagination></app-ui-pagination>
        <app-ui-alerts></app-ui-alerts>
      </div>
    </div>
  `,
})
export class UIComponentsComponent {
  control = new FormControl();
}
