import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-sidebar-item-icon',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FontAwesomeModule],
  styles: [
    `
      .square {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
      }

      .link--default {
        border-radius: 0.35rem;
        @apply mx-3;
        @apply flex min-w-min cursor-pointer items-center border;
      }

      .link--expandable {
        @apply cursor-pointer;
        @apply border border-transparent;
      }

      .link--active {
        @apply border-greenish-500;
        @apply text-greenish-500;
        @apply bg-greenish-25 dark:bg-greenish-800;
      }

      .link--not-active {
        @apply border-transparent hover:border-greenish-500;
        @apply hover:text-greenish-500 dark:text-dune-0;
        @apply bg-transparent hover:bg-greenish-25 hover:dark:bg-greenish-800;
      }

      .link--dropdown {
        border-radius: 0.35rem 0 0 0.35rem;
        @apply ml-3 flex h-min w-min;
        @apply flex min-w-min cursor-pointer items-center;
        @apply border-y border-l border-greenish-500;
        @apply text-greenish-500;
        @apply bg-greenish-25 dark:bg-greenish-800;
        padding-right: 0.8rem;
        margin-right: 0;
      }
    `,
  ],
  template: `
    <div
      [ngClass]="{
        'link--default': true,
        'link--active': isActive(),
        'link--not-active': !isActive(),
        'link--expandable': isExpandable(),
        'link--dropdown': isDropdown(),
      }"
      class="not-highlightable group">
      <div class="p-2">
        <div class="square">
          <fa-icon
            [ngClass]="{
              'text-greenish-500': highlightIcon(),
              'group-hover:text-greenish-500': hoverIcon(),
            }"
            [icon]="icon()"></fa-icon>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  `,
})
export class SidebarItemIconComponent {
  icon = input.required<IconProp>();
  isActive = input<boolean>(false);
  isExpandable = input<boolean>(false);
  isDropdown = input<boolean>(false);
  highlightIcon = input<boolean>(false);
  hoverIcon = input<boolean>(true);

  constructor(private library: FaIconLibrary) {
    this.library.addIcons(faChevronDown, faChevronUp);
  }
}
