.flkAlert__container {
  position: fixed;
  width: 400px;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}
