:root {
  --flkAlert__title-color: theme('colors.dune.800');
  --flkAlert__message-color: theme('colors.dune.600');

  --flkAlert__icon--success-color: theme(colors.greenish.500);
  --flkAlert--success-border: theme(colors.greenish.500);
  --flkAlert--success-background: theme(colors.greenish.25);

  --flkAlert__icon--info-color: theme(colors.azure.500);
  --flkAlert--info-border: theme(colors.azure.500);
  --flkAlert--info-background: theme(colors.azure.25);

  --flkAlert__icon--warn-color: theme(colors.supernova.500);
  --flkAlert--warn-border: theme(colors.supernova.500);
  --flkAlert--warn-background: theme(colors.supernova.25);

  --flkAlert__icon--error-color: theme(colors.torch.500);
  --flkAlert--error-border: theme(colors.torch.500);
  --flkAlert--error-background: theme(colors.torch.25);

  .dark {
    --flkAlert__title-color: theme('colors.dune.0');
    --flkAlert__message-color: theme('colors.dune.0');

    --flkAlert--success-background: theme(colors.greenish.800);
    --flkAlert--info-background: theme(colors.azure.800);
    --flkAlert--warn-background: theme(colors.supernova.800);
    --flkAlert--error-background: theme(colors.torch.800);
  }
}

.flkAlert {
  padding: 10px 10px 10px 25px;
  border-radius: 6px;
  border-left: 5px solid;
  max-width: 440px;
}

.flkAlert__content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flkAlert__icon {
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-start;
  cursor: pointer;
}

.flkAlert__title {
  color: var(--flkAlert__title-color);
  font-weight: 500;
}

.flkAlert__message {
  color: var(--flkAlert__message-color);
  font-weight: 400;
}

/** SUCCESS **/
.flkAlert--success {
  border-left-color: var(--flkAlert--success-border);
  background-color: var(--flkAlert--success-background);
}

.flkAlert--success .flkAlert__icon {
  color: var(--flkAlert__icon--success-color)
}

/** INFO **/
.flkAlert--info {
  border-left-color: var(--flkAlert--info-border);
  background-color: var(--flkAlert--info-background);
}

.flkAlert--info .flkAlert__icon {
  color: var(--flkAlert__icon--info-color)
}

/** WARN **/
.flkAlert--warn {
  border-left-color: var(--flkAlert--warn-border);
  background-color: var(--flkAlert--warn-background);
}

.flkAlert--warn .flkAlert__icon {
  color: var(--flkAlert__icon--warn-color)
}

/** ERROR **/
.flkAlert--error {
  border-left-color: var(--flkAlert--error-border);
  background-color: var(--flkAlert--error-background);
}

.flkAlert--error .flkAlert__icon {
  color: var(--flkAlert__icon--error-color)
}
