import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarItemIconComponent } from '@core/components/sidebar/components/sidebar-item/sidebar-item-icon.component';
import { SidebarItemStore } from '@core/components/sidebar/components/sidebar-item/sidebar-item.store';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';

@Component({
  selector: 'app-sidebar-item-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterLink,
    RouterLinkActive,
    SidebarItemIconComponent,
  ],
  template: `
    <a
      routerLinkActive
      (isActiveChange)="updateActiveRoute(mainPath(), $event)"
      [routerLinkActiveOptions]="{ exact: false }"
      routerLink="/{{ mainPath() }}">
      <app-sidebar-item-icon
        [isActive]="isActive()"
        [isExpandable]="isExpandable()"
        [isDropdown]="isDropdown()"
        [icon]="icon()"
        [highlightIcon]="highlightIcon()"
        [hoverIcon]="hoverIcon()">
        <ng-content></ng-content>
      </app-sidebar-item-icon>
    </a>
  `,
})
export class SidebarItemButtonComponent {
  icon = signal(this.sidebarItemStore.icon);
  mainPath = signal(this.sidebarItemStore.mainPath);

  isActive = input<boolean>(false);
  isExpandable = input<boolean>(false);
  isDropdown = input<boolean>(false);

  highlightIcon = input<boolean>(false);
  hoverIcon = input<boolean>(true);

  constructor(
    private sidebarItemStore: SidebarItemStore,
    private sidebarService: SidebarService
  ) {}

  updateActiveRoute(route: string, active: boolean) {
    if (active) {
      this.sidebarService.setActiveRoute(route);
    }
  }
}
