import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { SidebarItemStore } from '@core/components/sidebar/components/sidebar-item/sidebar-item.store';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { SidebarItemButtonComponent } from '@core/components/sidebar/components/sidebar-item/sidebar-item-button.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sidebar-item-collapsed-hover',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    FontAwesomeModule,
    SidebarItemButtonComponent,
  ],
  styles: [
    `
      .label--default {
        @apply mb-0.5 ml-1 mr-3 py-2 pl-2 pr-12;
        @apply w-min cursor-pointer;
        @apply flex min-w-min cursor-pointer items-center;
      }

      .label--active {
        text-underline-offset: 0.5em;
        text-decoration-thickness: 2px;
        font-weight: 500;
        text-decoration-color: theme(colors.greenish.500);
        text-decoration-line: underline;
      }

      .label--not-active:hover {
        text-underline-offset: 0.5em;
        text-decoration-thickness: 2px;
        text-decoration-line: underline;
        @apply decoration-dune-800 dark:decoration-dune-0;
      }

      .label-container {
        border-radius: 0 0 0.35rem 0.35rem;
        @apply border-x border-b border-dune-100 bg-dune-25 dark:border-dune-400 dark:bg-dune-900;
      }

      .title {
        border-radius: 0 0.35rem 0 0;
        @apply py-2 pl-2.5 pr-12;
        @apply border-y border-r border-greenish-500;
        @apply text-greenish-500;
        @apply bg-greenish-25 dark:bg-greenish-800;
      }
    `,
  ],
  template: `
    <div class="flex" (mouseleave)="exitItem.emit()">
      <div class="group flex w-min flex-col">
        <app-sidebar-item-button [isDropdown]="true" [highlightIcon]="true" />
        <div class="flex h-full w-full" (mouseenter)="exitItem.emit()"></div>
      </div>
      <div class="-ml-[1px] flex w-fit min-w-52 flex-col">
        <span class="title">{{ mainLabel() }}</span>
        <div class="test">
          <div class="label-container flex flex-col">
            <a
              *ngFor="
                let subPage of subPages();
                let first = first;
                let last = last
              "
              routerLink="/{{ subPage.path }}"
              routerLinkActive
              (isActiveChange)="updateActiveRoute(subPage.path, $event)">
              <span
                [ngClass]="{
                  'label--default': true,
                  'label--active': subPage.path === activeRoute(),
                  'label--not-active': subPage.path !== activeRoute(),
                  'pt-3': first,
                  'pb-3': last,
                }">
                {{ subPage.label }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class SidebarItemCollapsedHoverComponent {
  icon = signal(this.sidebarItemStore.icon);
  mainLabel = signal(this.sidebarItemStore.mainLabel);
  subPages = signal(this.sidebarItemStore.subPages);
  activeRoute = toSignal(this.sidebarService.routeChanged);

  hovering = input<boolean>(false);

  exitItem = output<void>();

  constructor(
    private library: FaIconLibrary,
    private sidebarItemStore: SidebarItemStore,
    private sidebarService: SidebarService
  ) {
    this.library.addIcons(faChevronDown, faChevronUp);
  }

  updateActiveRoute(route: string, active: boolean) {
    if (active) {
      this.sidebarService.setActiveRoute(route);
    }
  }
}
