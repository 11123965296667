import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarItemStore } from '@core/components/sidebar/components/sidebar-item/sidebar-item.store';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { SidebarItemButtonComponent } from '@core/components/sidebar/components/sidebar-item/sidebar-item-button.component';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-sidebar-item-extended',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FontAwesomeModule, SidebarItemButtonComponent],
  template: `
    <li class="my-0.5 w-full">
      <app-sidebar-item-button
        [isActive]="routerLinkActive()"
        [highlightIcon]="routerLinkActive()">
        <span class="text-nowrap pr-12 group-hover:text-greenish-500">
          {{ mainLabel() }}
        </span>
      </app-sidebar-item-button>
    </li>
  `,
})
export class SidebarItemExtendedComponent implements OnInit, OnDestroy {
  icon = signal(this.sidebarItemStore.icon);
  mainLabel = signal(this.sidebarItemStore.mainLabel);
  mainPath = signal(this.sidebarItemStore.mainPath);
  routerLinkActive = signal<boolean>(false);

  private subscription: Subscription;

  constructor(
    private sidebarItemStore: SidebarItemStore,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.subscription = this.sidebarService.routeChanged.subscribe(route => {
      this.routerLinkActive.set(this.mainPath() === route);
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
