import { Component, ViewEncapsulation } from '@angular/core';
import { AlertService } from './alert.service';
import { AlertComponent } from './alert.component';
import { Alert } from './alert';

@Component({
  selector: 'flk-alert-container',
  encapsulation: ViewEncapsulation.None,
  imports: [AlertComponent],
  styleUrl: './alert-container.component.css',
  template: `
    <div class="flkAlert__container">
      @for (alert of alerts(); track $index) {
        <flk-alert [alert]="alert" (closeClicked)="onClose($event)" />
      }
    </div>
  `,
})
export class AlertContainerComponent {
  /**
   * Alerts to be displayed
   * **/
  readonly alerts = this.alertService.alerts;
  constructor(private readonly alertService: AlertService) {}

  /**
   * Close the alert
   * **/
  onClose(alert: Alert): void {
    this.alertService.dismiss(alert);
  }
}
