import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  OnDestroy,
  output,
} from '@angular/core';
import { filter, fromEvent, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

/**
 * This directive can be used for to handle outside clicks of a specific component
 * E.g. see NavMenuComponent
 */
@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  /** OUTPUTS **/
  clickOutside = output<void>();

  private subscription: Subscription | undefined;

  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit(): void {
    this.subscription = fromEvent(this.document, 'click')
      .pipe(filter(event => !this.isInside(event.target as HTMLElement)))
      .subscribe(() => this.clickOutside.emit());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private isInside(elementToCheck: HTMLElement): boolean {
    return (
      elementToCheck === this.element.nativeElement ||
      this.element.nativeElement.contains(elementToCheck)
    );
  }
}
