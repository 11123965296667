import { Component, input } from '@angular/core';
import {
  ButtonAppearance,
  ButtonVariant,
} from '@flowkit/button/button-base.directive';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { ButtonComponent } from '@flowkit/button/button.component';
import { IconButtonComponent } from '@flowkit/button/icon-button.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-my-buttons',
  imports: [ButtonComponent, IconButtonComponent],
  templateUrl: 'buttons.component.html',
})
export class ButtonsComponent {
  header = input.required<string>();
  appearance = input.required<ButtonAppearance>();
  variants: ButtonVariant[] = ['accent', 'info', 'default', 'warn'];
  buttonText = 'Save & Next';

  constructor(library: FaIconLibrary) {
    library.addIcons(faClone);
  }
}
