import {
  Component,
  computed,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationShowingCountComponent } from '@flowkit/pagination/page-information/pagination-showing-count.component';
import { PaginationRowsPerPageComponent } from '@flowkit/pagination/page-information/pagination-rows-per-page.component';
import { PaginationPageNavComponent } from '@flowkit/pagination/page-nav/pagination-page-nav.component';

@Component({
  selector: 'flk-pagination',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    PaginationRowsPerPageComponent,
    PaginationShowingCountComponent,
    PaginationPageNavComponent,
  ],
  styles: [
    `
      .pagination-info > * {
        display: inline-block;
      }

      .pagination {
        @apply flex flex-col pb-1 pt-3 text-sm sm:flex-row sm:items-center sm:justify-between;
      }
    `,
  ],
  template: `
    <div class="pagination">
      <div class="pagination-info">
        <flk-pagination-showing-count
          [totalCount]="totalCount()"
          [currentPage]="currentPage()"
          [pageSize]="pageSize()"
          [upperBound]="upperBound()">
        </flk-pagination-showing-count>

        <flk-pagination-rows-per-page
          [pageSize]="pageSize()"
          [stepCount]="stepCount()"
          [stepSize]="stepSize()"
          (updateSize)="changePageSize.emit($event)">
        </flk-pagination-rows-per-page>
      </div>

      <flk-pagination-page-nav
        [currentPage]="currentPage()"
        [totalPages]="totalPages()"
        (changePage)="changePage.emit($event)">
      </flk-pagination-page-nav>
    </div>
  `,
})
export class PaginationComponent {
  /**
   * INPUTS
   */
  initialValue = input<number | undefined>();
  totalCount = input.required<number>();
  currentPage = input.required<number>();
  pageSize = input.required<number>();

  stepCount = input.required<number>();
  stepSize = input.required<number>();

  /**
   * OUTPUTS
   **/
  changePageSize = output<number>();
  changePage = output<number>();

  /**
   * PROPERTIES
   */
  totalPages = computed(() => Math.ceil(this.totalCount() / this.pageSize()));
  upperBound = computed(() =>
    this.currentPage() * this.pageSize() > this.totalCount()
      ? this.totalCount()
      : this.currentPage() * this.pageSize()
  );
}
