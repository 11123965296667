import { Routes } from '@angular/router';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { Layout } from '@core/enums/layout';
import { appGuard } from '@core/guards/app.guard';
import { setLayout } from '@core/resolvers/layout.resolver';
import { hasRoleGuard } from '@core/guards/has-role.guard';
import { hasFeatureEnabledGuard } from '@core/feature-preview/has-feature-enabled.guard';
import { provideDevRoute } from '@features/dev/provide-dev-route';
import { UserRole } from '@corezilla/api';

export const APP_ROUTES: Routes = [
  /**
   * DEFAULT routes
   */
  {
    path: '',
    redirectTo: 'process-definitions',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    title: 'Authentication',
    resolve: { layout: setLayout(Layout.AUTH) },
    loadChildren: () =>
      import('@features/auth/auth.routes').then(m => m.AUTH_ROUTES),
  },
  {
    path: 'profile',
    title: 'Profile',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Profile', icon: ['fas', 'address-card'] },
    loadChildren: () =>
      import('@features/profile/profile.routes').then(m => m.PROFILE_ROUTES),
    canActivate: [appGuard],
  },
  {
    path: 'admin',
    title: 'Admin',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Admin', icon: ['fas', 'user-tie'] },
    loadChildren: () =>
      import('@features/admin/admin.routes').then(m => m.ADMIN_ROUTES),
    canActivate: [appGuard],
  },

  /**
   * STANDARD USER routes
   */
  {
    path: 'dashboard',
    title: 'Dashboard',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Dashboard', icon: ['fas', 'chart-line'] },
    loadChildren: () =>
      import('@features/dashboard/dashboard.routes').then(
        m => m.DASHBOARD_ROUTES
      ),
    canActivate: [appGuard, hasFeatureEnabledGuard('Dashboard')],
  },
  {
    path: 'workflows',
    title: 'Workflows',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Workflows', icon: ['fas', 'circle-nodes'] },
    loadChildren: () =>
      import('@features/workflows/process-contracts.routes').then(
        m => m.PROCESS_CONTRACT_ROUTES
      ),
    canActivate: [appGuard, hasFeatureEnabledGuard('Workflow')],
  },
  {
    path: 'tenants',
    title: 'Access Hub',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Access Hub', icon: ['fas', 'building-shield'] },
    loadChildren: () =>
      import('@features/tenants/tenants.routes').then(m => m.TENANT_ROUTES),
    canActivate: [appGuard],
  },
  {
    path: 'process-definitions',
    title: 'Process Definitions',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Process Definitions', icon: ['fas', 'code-merge'] },
    loadChildren: () =>
      import('@features/process-definitions/process-definitions.routes').then(
        m => m.PROCESS_DEFINITION_ROUTES
      ),
    canActivate: [appGuard],
  },
  /**
   {
   path: 'detection-definitions',
   title: 'Detection Definitions',
   resolve: { layout: setLayout(Layout.ADMIN) },
   data: { breadcrumb: 'Detection Definitions', icon: ['fas', 'code-fork'] },
   loadChildren: () =>
   import('@features/detection-definitions/detection-definitions.routes').then(
   m => m.DETECTION_DEFINITION_ROUTES
   ),
   canActivate: [appGuard],
   }, */
  {
    path: 'process-instances',
    title: 'Process Runs',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Process Runs', icon: ['fas', 'rocket'] },
    loadChildren: () =>
      import('@features/process-instances/process-instances.routes').then(
        m => m.PROCESS_INSTANCE_ROUTES
      ),
    canActivate: [appGuard],
  },
  {
    path: 'detection-instances',
    title: 'Trigger Runs',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Trigger Runs', icon: ['fas', 'space-shuttle'] },
    loadChildren: () =>
      import('@features/detection-instances/detection-instances.routes').then(
        m => m.DETECTION_INSTANCES_ROUTES
      ),
    canActivate: [appGuard],
  },
  {
    path: 'global-changelogs',
    title: 'Global Changelogs',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Global Changelogs', icon: ['fas', 'list'] },
    loadChildren: () =>
      import('@features/global-changelogs/global-changelogs.routes').then(
        m => m.GLOBAL_CHANGELOGS_ROUTES
      ),
    canActivate: [appGuard],
  },
  {
    path: 'tags',
    title: 'Tags',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Tags', icon: ['fas', 'tags'] },
    loadChildren: () =>
      import('@features/tags/tags.routes').then(m => m.TAGS_ROUTES),
    canActivate: [appGuard],
  },

  /**
   * SETTINGS routes
   */
  /*{
    path: 'app-settings',
    title: 'Application',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Application', icon: ['fas', 'gears'] },
    loadChildren: () =>
      import('@features/app-settings/app-settings.routes').then(m => m.APP_SETTINGS_ROUTES),
    canActivate: [appGuard],
  },*/
  {
    path: 'users',
    title: 'Users',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Users', icon: ['fas', 'users'] },
    loadChildren: () =>
      import('@features/users/user.routes').then(m => m.USER_ROUTES),
    canActivate: [appGuard],
  },

  /**
   * SUPER-ADMIN / STAFF routes
   */
  {
    path: 'application-configurations',
    title: 'Application Configurations',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: {
      breadcrumb: 'Application Configurations',
      icon: ['fas', 'gear'],
    },
    loadChildren: () =>
      import(
        '@features/application-configurations/application-configuration.routes'
      ).then(m => m.PROCESS_INSTANCE_ROUTES),
    canActivate: [appGuard, hasRoleGuard([UserRole.ROLE_SUPER_ADMIN])],
  },
  {
    path: 'component-definitions',
    title: 'Components',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Components', icon: ['fas', 'layer-group'] },
    loadChildren: () =>
      import(
        '@features/component-definitions/component-definitions.routes'
      ).then(m => m.COMPONENT_DEFINITION_ROUTES),
    canActivate: [appGuard, hasRoleGuard([UserRole.ROLE_SUPER_ADMIN])],
  },
  {
    path: 'detection-type-definitions',
    title: 'Trigger Types',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: {
      breadcrumb: 'Trigger Types',
      icon: ['fas', 'stethoscope'],
    },
    loadChildren: () =>
      import(
        '@features/detection-type-definitions/detection-type-definitions.routes'
      ).then(m => m.DETECTION_TYPE_DEFINITION_ROUTES),
    canActivate: [appGuard, hasRoleGuard([UserRole.ROLE_SUPER_ADMIN])],
  },
  /*{
    path: 'assets',
    title: 'Assets',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: {
      breadcrumb: 'Assets',
      icon: ['fas', 'images'],
    },
    loadChildren: () =>
      import(
        '@features/assets/assets.routes'
      ).then(m => m.ASSETS_ROUTES),
    canActivate: [appGuard, hasRoleGuard([Role.ROLE_SUPER_ADMIN])],
  },*/
  {
    path: 'process-throttles',
    title: 'Process Throttles',
    resolve: { layout: setLayout(Layout.ADMIN) },
    data: { breadcrumb: 'Process Throttles', icon: ['fas', 'gauge-high'] },
    loadChildren: () =>
      import('@features/process-throttle/process-throttle.routes').then(
        m => m.PROCESS_THROTTLE_ROUTES
      ),
    canActivate: [appGuard, hasRoleGuard([UserRole.ROLE_SUPER_ADMIN])],
  },

  /**
   * EXTRA routes
   */
  provideDevRoute(),
  {
    path: '**',
    title: '404',
    loadComponent: () => NotFoundComponent,
  },
];
