.flkBadge__container {
  @apply inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset;
}

.flkBadge__container > .flkBadge__btn-remove {
  @apply relative -mr-1 size-3.5 rounded-sm;
}

/**
* ACCENT BADGE
*/
.flkBadge--accent > .flkBadge__container {
  @apply bg-greenish-25 text-greenish-500 ring-greenish-500/10 dark:bg-greenish-800 dark:ring-green-100/25;

  > .flkBadge__btn-remove:hover {
    @apply bg-greenish-500/20 dark:bg-greenish-25/25;
  }
}

/**
* DEFAULT BADGE
*/
.flkBadge--default > .flkBadge__container {
  @apply bg-dune-100 text-dune-500 ring-dune-500/10 dark:bg-dune-800 dark:ring-dune-100/25 dark:text-dune-100;

  > .flkBadge__btn-remove:hover {
    @apply bg-dune-500/20 dark:bg-dune-100/25;
  }
}
