import { Component } from '@angular/core';
import { ButtonsComponent } from '@features/dev/ui-components/buttons/buttons.component';
import { InteractiveButtonsComponent } from '@features/dev/ui-components/buttons/interactive-buttons.component';

@Component({
  imports: [ButtonsComponent, InteractiveButtonsComponent],
  selector: 'app-ui-buttons',
  template: `
    <div>
      <h1 class="heading-title">Buttons</h1>
      <div class="flex flex-col gap-10">
        <app-my-buttons [appearance]="'primary'" [header]="'Primary'" />
        <app-my-buttons [appearance]="'secondary'" [header]="'Secondary'" />
        <app-my-buttons [appearance]="'tertiary'" [header]="'Tertiary'" />
        <app-my-buttons [appearance]="'ghost'" [header]="'Ghost'" />
        <app-my-interactive-buttons />
      </div>
    </div>
  `,
})
export class UIButtonsComponent {}
