import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { SidebarItemExtendedExpandableComponent } from '@core/components/sidebar/components/sidebar-item/item/extended/sidebar-item-extended-expandable.component';
import { SidebarItemCollapsedComponent } from '@core/components/sidebar/components/sidebar-item/item/collapsed/sidebar-item-collapsed.component';
import {
  provideSidebarItemStore,
  SidebarItemStore,
  SubPage,
} from '@core/components/sidebar/components/sidebar-item/sidebar-item.store';
import { SidebarItemExtendedComponent } from '@core/components/sidebar/components/sidebar-item/item/extended/sidebar-item-extended.component';

@Component({
  selector: 'app-sidebar-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideSidebarItemStore()],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SidebarItemExtendedExpandableComponent,
    SidebarItemCollapsedComponent,
    SidebarItemExtendedComponent,
  ],
  template: `
    @if (!collapsed()) {
      @if (subPages()) {
        <app-sidebar-item-extended-expandable></app-sidebar-item-extended-expandable>
      } @else {
        <app-sidebar-item-extended></app-sidebar-item-extended>
      }
    } @else {
      <app-sidebar-item-collapsed></app-sidebar-item-collapsed>
    }
  `,
})
export class SidebarItemComponent implements OnInit {
  icon = input.required<IconProp>();
  label = input.required<string>();
  path = input.required<string>();
  subPages = input<SubPage[]>();

  collapsed = toSignal(this.sidebarService.sidebarCollapsed$);

  constructor(
    private sidebarService: SidebarService,
    private sidebarItemStore: SidebarItemStore,
    private library: FaIconLibrary
  ) {
    this.library.addIcons(faChevronDown, faChevronUp);
  }

  ngOnInit() {
    this.sidebarItemStore.init(
      this.icon(),
      this.label(),
      this.path(),
      this.subPages() ? this.subPages()! : null
    );
  }
}
