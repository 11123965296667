import { inject } from '@angular/core';
import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  selectHasRole,
  selectIsLoggedIn,
} from '@core/states/auth/auth.selectors';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { LOGIN_PATH } from '@features/auth/auth.routes';
import { UserRole } from '@corezilla/api';

export const hasRoleGuard = (roles: UserRole[]): CanActivateFn => {
  return () => {
    const store = inject(Store);
    const router = inject(Router);

    const urlTree = router.createUrlTree([LOGIN_PATH]);
    const redirect = new RedirectCommand(urlTree);
    return store.select(selectIsLoggedIn).pipe(
      filter(status => status),
      switchMap(() => store.select(selectHasRole(roles))),
      map(hasRole => (hasRole ? true : redirect)),
      catchError(() => of(redirect))
    );
  };
};
