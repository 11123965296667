import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FaIconComponent,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Alert } from './alert';

@Component({
  selector: 'flk-alert',
  imports: [FaIconComponent],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './alert.component.css',
  template: `
    <div class="flkAlert__content">
      <p class="flkAlert__title">{{ alert().title }}</p>
      <p class="flkAlert__message">{{ alert().message }}</p>
      @if (alert().showClose) {
        <fa-icon
          class="flkAlert__icon"
          [icon]="['fas', 'xmark']"
          (click)="onClose()" />
      }
    </div>
  `,
})
export class AlertComponent {
  /**
   * The alert to display.
   * **/
  alert = input.required<Alert>();

  /**
   * Emits when the close button is clicked.
   * **/
  closeClicked = output<Alert>();

  private readonly cssClass = 'flkAlert';

  constructor(library: FaIconLibrary) {
    library.addIcons(faXmark);
  }

  @HostBinding('class')
  public get hostClasses() {
    return {
      [`${this.cssClass}`]: true,
      [`${this.cssClass}--${this.alert().variant}`]: true,
    };
  }

  public onClose(): void {
    this.closeClicked.emit(this.alert());
  }
}
