import { Component } from '@angular/core';
import { Alert, AlertComponent, AlertService } from '@flowkit/alert';
import { ButtonComponent } from '@flowkit/button';

@Component({
  selector: 'app-ui-alerts',
  imports: [AlertComponent, ButtonComponent],
  template: `
    <h1 class="heading-title">Alerts</h1>
    <div
      class="flex flex-col gap-5 [&>div]:flex [&>div]:gap-5 [&_flk-alert]:w-full">
      <div>
        <flk-alert [alert]="alertSuccess" />
        <button flkButton [variant]="'accent'" (click)="openSuccess()">
          Open Success
        </button>
      </div>
      <div>
        <flk-alert [alert]="alertInfo" />
        <button flkButton [variant]="'info'" (click)="openInfo()">
          Open Info
        </button>
      </div>
      <div>
        <flk-alert [alert]="alertWarn" />
        <button flkButton [variant]="'default'" (click)="openWarning()">
          Open Warning
        </button>
      </div>
      <div>
        <flk-alert [alert]="alertError" />
        <button flkButton [variant]="'warn'" (click)="openError()">
          Open Error
        </button>
      </div>
    </div>
  `,
})
export class UIAlertsComponent {
  alertSuccess = {
    title: 'Foo',
    message: 'Bar',
    variant: 'success',
  } as Alert;

  alertInfo = {
    title: 'Foo',
    message: 'Bar',
    variant: 'info',
  } as Alert;

  alertWarn = {
    title: 'Foo',
    message: 'Bar',
    variant: 'warn',
    showClose: true,
  } as Alert;

  alertError = {
    title: 'Foo',
    message: 'Bar',
    variant: 'error',
    showClose: true,
  } as Alert;

  constructor(private alertService: AlertService) {}

  openSuccess() {
    this.alertService.showSuccess({ title: 'foo', message: 'Lorem ipsum' });
  }

  openInfo() {
    this.alertService.showInfo({ title: 'foo', message: 'Lorem ipsum' });
  }

  openWarning() {
    this.alertService.showWarning({ title: 'foo', message: 'Lorem ipsum' });
  }

  openError() {
    this.alertService.showError({ title: 'foo', message: 'Lorem ipsum' });
  }
}
