import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureKey } from '@core/feature-preview/types/feature-key';
import { isFeatureEnabled } from '@core/feature-preview/is-feature-enabled';

export const hasFeatureEnabledGuard = (key: FeatureKey): CanActivateFn => {
  return () => {
    const router = inject(Router);
    const urlTree = router.createUrlTree(['/']);
    const redirect = new RedirectCommand(urlTree);
    return isFeatureEnabled(key) ? true : redirect;
  };
};
