import { Component } from '@angular/core';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { InteractiveButtonComponent } from '@flowkit/button';

@Component({
  selector: 'app-my-interactive-buttons',
  imports: [InteractiveButtonComponent],
  template: `
    <div>
      <h2 class="heading-title--small">Interactive</h2>
      <div class="flex flex-col gap-5">
        @for (variant of variants; track $index) {
          <div class="flex gap-3">
            <button
              flkInteractiveButton
              [icon]="['fas', 'clone']"
              [tooltip]="'Save & Next'"
              [variant]="variant"></button>
            <button
              flkInteractiveButton
              [disabled]="true"
              [icon]="['fas', 'clone']"
              [tooltip]="'Save & Next'"
              [variant]="variant"></button>
          </div>
        }
      </div>
    </div>
  `,
})
export class InteractiveButtonsComponent {
  variants: ['accent', 'info', 'warn'] = ['accent', 'info', 'warn'];

  constructor(library: FaIconLibrary) {
    library.addIcons(faClone);
  }
}
