import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ToggleComponent } from '@flowkit/toggle';

@Component({
    selector: 'app-ui-toggles',
    imports: [ToggleComponent, ReactiveFormsModule],
    template: `
    <div>
      <h1 class="heading-title">Toggles</h1>
      <div class="flex gap-10">
        <div class="flex flex-col justify-between gap-5">
          <flk-toggle [formControl]="control1"></flk-toggle>
          <flk-toggle [checked]="false"></flk-toggle>
        </div>
        <div class="flex flex-col justify-between gap-5">
          <flk-toggle [formControl]="control2"></flk-toggle>
          <flk-toggle [disabled]="true"></flk-toggle>
        </div>
        <div class="flex flex-col justify-between gap-5">
          <flk-toggle [checked]="true" class="reverse">Enable</flk-toggle>
          <flk-toggle [disabled]="false" class="reverse">Enable</flk-toggle>
        </div>
        <div class="flex flex-col justify-between gap-5">
          <flk-toggle [checked]="true" [disabled]="true">Enable</flk-toggle>
          <flk-toggle [disabled]="true">Enable</flk-toggle>
        </div>
      </div>
    </div>
  `
})
export class UITogglesComponent {
  control1 = new FormControl(true);
  control2 = new FormControl({ value: true, disabled: true });
}
