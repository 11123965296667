import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';

export type BadgeVariant = 'accent' | 'default';

@Component({
  standalone: true,
  selector: 'flk-badge',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['badge.component.css'],
  template: `
    <span class="flkBadge__container">
      {{ label() }}
      @if (!hideRemove()) {
        <button
          type="button"
          class="flkBadge__btn-remove group"
          (click)="onRemoveClicked()">
          <span class="sr-only">Remove</span>
          <svg viewBox="0 0 14 14" class="size-3.5 stroke-current">
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span class="absolute -inset-1"></span>
        </button>
      }
    </span>
  `,
})
export class BadgeComponent {
  /** INPUTS **/
  /**
   * The label to display in the badge.
   */
  label = input.required<string>();

  /**
   * The variant of badge. Defaults to 'default'.
   */
  variant = input<BadgeVariant>('default');

  /**
   * Whether to show the close button. Defaults to true.
   **/
  hideRemove = input<boolean>(false);

  /** OUTPUTS **/

  /**
   * Emitted when the close button is clicked.
   */
  removeClicked = output<void>();

  /** PROPERTIES **/

  /**
   * The base class name for styling the button.
   */
  private readonly cssClass = `flkBadge`;

  @HostBinding('class')
  public get hostClasses() {
    return {
      [`${this.cssClass}`]: true,
      [`${this.cssClass}--${this.variant()}`]: true,
    };
  }

  public onRemoveClicked(): void {
    this.removeClicked.emit();
  }
}
