import { createReducer, on } from '@ngrx/store';
import { AuthState, initialAuthState } from '@core/states/auth/auth.state';
import * as AuthActions from '@core/states/auth/auth.actions';
import { ViewState } from '@core/enums/view-state';
import { CrudState } from '@core/enums/crud-state';

export const authReducers = createReducer(
  initialAuthState,
  /**
   * LOGIN
   * **/
  on(AuthActions.login, (state: AuthState) => ({
    ...state,
    viewState: ViewState.Loading,
    viewStateError: undefined,
  })),
  on(AuthActions.loginSuccess, (state: AuthState, { user }) => ({
    ...state,
    user,
    viewState: ViewState.Data,
    viewStateError: undefined,
  })),
  on(AuthActions.loginError, (state: AuthState, { error }) => ({
    ...state,
    user: undefined,
    viewState: ViewState.Error,
    viewStateError: error,
  })),
  /**
   * LOGOUT
   * **/
  on(AuthActions.logout, (state: AuthState) => ({
    ...state,
    viewState: ViewState.Loading,
    viewStateError: undefined,
  })),
  on(AuthActions.logoutError, (state: AuthState, { error }) => ({
    ...state,
    viewState: ViewState.Error,
    viewStateError: error,
  })),
  /**
   * UPDATE USER
   * **/
  on(AuthActions.updateAuthUser, state => ({
    ...state,
    crudState: CrudState.UPDATE,
    crudStateError: undefined,
  })),
  on(AuthActions.updateAuthUserPassword, (state: AuthState) => ({
    ...state,
    crudState: CrudState.UPDATE,
    crudStateError: undefined,
  })),
  on(AuthActions.updateAuthUserSuccess, (state, { updated }) => ({
    ...state,
    user: updated,
    crudState: undefined,
    crudStateError: undefined,
  })),
  on(AuthActions.updateAuthUserError, (state, { error }) => ({
    ...state,
    crudState: CrudState.ERROR,
    crudStateError: error,
  })),
  /**
   * SET USER
   * **/
  on(AuthActions.setAuthUser, (state, { user }) => ({
    ...state,
    user,
    viewState: ViewState.Data,
    viewStateError: undefined,
  })),
  on(AuthActions.setAuthError, (state, { error }) => ({
    ...state,
    user: undefined,
    viewState: ViewState.Error,
    viewStateError: error,
  })),
  /**
   * RESET
   * **/
  on(AuthActions.removeAuthUser, state => ({
    ...state,
    user: undefined,
    viewState: ViewState.Initial,
    viewStateError: undefined,
    crudState: undefined,
    crudStateError: undefined,
  }))
);
