import { AuthApi, COREZILLA_API_CONFIG, UsersApi } from '@corezilla/api';
import { environment } from '@environment/environment';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from '@core/states/auth/auth.effects';
import { provideState } from '@ngrx/store';
import { authReducers } from '@core/states/auth/auth.reducers';

export const provideAuthServices = () => {
  return [
    provideState({ name: 'auth', reducer: authReducers }),
    provideEffects(AuthEffects),
    AuthApi,
    UsersApi,
    {
      provide: COREZILLA_API_CONFIG,
      useValue: environment,
    },
  ];
};
