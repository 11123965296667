<section class="flex h-content items-center justify-center">
  <div>
    <div class="flex justify-center">
      <img class="object-contain max-w-48 sm:max-w-52 xl:max-w-72 size-full"
           [ngSrc]="'./assets/images/state-404.svg'"
           width="126" height="150"
           alt="Error: 404 Page not Found"
           priority
      />
    </div>
    <h1 class="heading-headline justify-center flex">Error: 404, We are sorry</h1>
    <p class="heading-subtitle mt-5 justify-center flex">This page was not found.</p>
  </div>
</section>
