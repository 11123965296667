import { Component, computed, input, output } from '@angular/core';
import { PaginationPageStepperComponent } from '@flowkit/pagination/page-nav/pagination-page-stepper.component';
import { PaginationPageOptionComponent } from '@flowkit/pagination/page-nav/pagination-page-option.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'flk-pagination-page-nav',
  imports: [
    PaginationPageStepperComponent,
    PaginationPageStepperComponent,
    PaginationPageStepperComponent,
    PaginationPageOptionComponent,
  ],
  styleUrls: ['../pagination.css'],
  template: `
    <nav class="isolate inline-flex" aria-label="Pagination">
      <flk-pagination-page-stepper
        class="flex"
        [icon]="['fas', 'angle-left']"
        [tooltip]="'Previous Page'"
        [label]="'Previous'"
        [disabled]="leftStepperDisabled()"
        (clicked)="onChangePage(currentPage() - 1)">
      </flk-pagination-page-stepper>
      @for (page of availablePages(); track $index; let last = $last) {
        <flk-pagination-page-option
          [page]="page !== null ? page : -1"
          [label]="page !== null ? page.toString() : '...'"
          [disabled]="page === null"
          [currentPage]="currentPage()"
          (pageChosen)="onChangePage($event)"
          [last]="last">
        </flk-pagination-page-option>
      }
      <flk-pagination-page-stepper
        class="flex"
        [icon]="['fas', 'angle-right']"
        [tooltip]="'Next Page'"
        [label]="'Next'"
        [disabled]="rightStepperDisabled()"
        (clicked)="onChangePage(currentPage() + 1)">
      </flk-pagination-page-stepper>
    </nav>
  `,
})
export class PaginationPageNavComponent {
  /** INPUTS **/

  /** currently chosen page **/
  currentPage = input.required<number>();

  /** total number of pages **/
  totalPages = input.required<number>();

  /** OUTPUTS **/

  /** signals a new page was chosen **/
  changePage = output<number>();

  leftStepperDisabled = computed(() => !(this.currentPage() > 1));

  rightStepperDisabled = computed(
    () => !(this.currentPage() < this.totalPages() - 1)
  );

  /** list of available page numbers **/
  availablePages = computed(() => this.calculateAvailablePages());

  constructor(private library: FaIconLibrary) {
    this.library.addIcons(faAngleLeft, faAngleRight);
  }

  onChangePage(newPage: number): void {
    if (newPage > 0 && newPage <= this.totalPages()) {
      this.changePage.emit(newPage);
    }
  }

  private calculateAvailablePages() {
    const total = this.totalPages();
    const current = this.currentPage();

    const boundary = 2; // Anzahl der Seiten um die aktuelle Seite

    const array: (number | null)[] = [];

    // Erste Seite und ggf. Auslassungspunkte
    array.push(1);
    if (current - boundary > 2) {
      array.push(null); // für "..."
    }

    // Seiten um die aktuelle Seite
    for (
      let i = Math.max(2, current - boundary);
      i <= Math.min(total - 1, current + boundary);
      i++
    ) {
      array.push(i);
    }

    // Letzte Seite und ggf. Auslassungspunkte
    if (current + boundary < total - 1) {
      array.push(null); // für "..."
    }
    if (total > 1) {
      array.push(total);
    }

    return array;
  }
}
