import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'flk-pagination-showing-count',
  styleUrls: ['../pagination.css'],
  template: `
    <div class="text-nowrap pl-1 pr-10 text-dune-500">
      @if (totalCount() > 0) {
        <span class="text--bold">{{ firstIndex() }}</span>
        <span class="px-0.5">-</span>
        <span class="text--bold">{{ upperBound() }}</span>
      } @else {
        <span class="text--bold">0</span>
      }
      <span class="sm:text-left"> of </span>
      <span class="text--bold">{{ totalCount() }}</span>
    </div>
  `,
})
export class PaginationShowingCountComponent {
  /**
   * INPUTS
   * **/
  totalCount = input.required<number>();
  currentPage = input.required<number>();
  pageSize = input.required<number>();
  upperBound = input.required<number>();

  protected firstIndex = computed(
    () => (this.currentPage() - 1) * this.pageSize() + 1
  );
}
