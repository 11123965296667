import {
  ChangeDetectionStrategy,
  Component,
  computed,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { SidebarItemStore } from '@core/components/sidebar/components/sidebar-item/sidebar-item.store';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { SidebarItemIconComponent } from '@core/components/sidebar/components/sidebar-item/sidebar-item-icon.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sidebar-item-extended-expandable',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    FontAwesomeModule,
    SidebarItemIconComponent,
  ],
  styles: [
    `
      .label--default {
        @apply ml-1 mr-3 pb-1.5 pl-2 pr-12 pt-1;
        @apply w-min cursor-pointer;
        @apply flex min-w-min cursor-pointer items-center;
      }

      .label--active {
        text-underline-offset: 0.5em;
        text-decoration-thickness: 2px;
        font-weight: 500;
        text-decoration-color: theme(colors.greenish.500);
        text-decoration-line: underline;
      }

      .label--not-active:hover {
        text-underline-offset: 0.5em;
        text-decoration-thickness: 2px;
        text-decoration-line: underline;
        @apply decoration-dune-800 dark:decoration-dune-0;
      }

      .label--extended-sidebar {
        padding-left: 2.6rem;
        padding-bottom: 0.6rem;
        @apply flex flex-col;
      }

      .icon--chevron {
        padding-right: 0.5rem;
        @apply cursor-pointer;
        @apply group-hover:text-greenish-500 group-hover:dark:text-greenish-500;
      }
    `,
  ],
  template: `
    <li class="my-0.5 flex w-full">
      <div class="w-full flex-col">
        <div (click)="onClick($event)">
          <div class="group flex w-full justify-between">
            <app-sidebar-item-icon
              class="flex-grow group-hover:text-greenish-500"
              [isActive]="routerLinkActive()"
              [isExpandable]="isExpandable()"
              [icon]="icon()"
              [highlightIcon]="routerLinkActive()"
              [hoverIcon]="true">
              <div class="flex w-full justify-between">
                <span class="text-nowrap">
                  {{ mainLabel() }}
                </span>

                @if (!isExpanded()) {
                  <fa-icon
                    class="icon--chevron"
                    [ngClass]="{ 'text-greenish-500': routerLinkActive() }"
                    [icon]="['fas', 'chevron-down']"></fa-icon>
                } @else {
                  <fa-icon
                    class="icon--chevron pt-1"
                    [ngClass]="{ 'text-greenish-500': routerLinkActive() }"
                    [icon]="['fas', 'chevron-up']"></fa-icon>
                }
              </div>
            </app-sidebar-item-icon>
          </div>
        </div>
        <div
          class="label--extended-sidebar not-highlightable"
          [ngClass]="{ hidden: !isExpanded() }">
          <a
            *ngFor="let subPage of subPages()"
            routerLink="/{{ subPage.path }}"
            routerLinkActive
            (isActiveChange)="updateActiveRoute(subPage.path, $event)">
            <span
              [ngClass]="{
                'label--default': true,
                'label--active': subPage.path === activeRoute(),
                'label--not-active': subPage.path !== activeRoute(),
              }">
              {{ subPage.label }}
            </span>
          </a>
        </div>
      </div>
    </li>
  `,
})
export class SidebarItemExtendedExpandableComponent {
  icon = signal(this.sidebarItemStore.icon);
  mainLabel = signal(this.sidebarItemStore.mainLabel);
  subPages = signal(this.sidebarItemStore.subPages);
  activeRoute = toSignal(this.sidebarService.routeChanged);
  isExpanded = signal<boolean>(false);
  isExpandable = signal(true);

  routerLinkActive = computed(() => {
    return this.subPages()!.some(page => page.path === this.activeRoute());
  });

  constructor(
    private library: FaIconLibrary,
    private sidebarItemStore: SidebarItemStore,
    private sidebarService: SidebarService
  ) {
    this.library.addIcons(faChevronDown, faChevronUp);
  }

  updateActiveRoute(route: string, active: boolean) {
    if (active) {
      this.sidebarService.setActiveRoute(route);
    }
  }

  onClick(event: Event) {
    this.isExpanded.set(!this.isExpanded());
    event.stopPropagation();
  }
}
