import { Component, computed, input, output, signal } from '@angular/core';
import {
  FaIconComponent,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { NgClass, NgForOf } from '@angular/common';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ClickOutsideDirective } from '@flowkit/utilities/directives/click-outside.directive';

@Component({
  selector: 'flk-pagination-rows-per-page',
  imports: [FaIconComponent, NgForOf, NgClass, ClickOutsideDirective],
  styleUrls: ['../pagination.css'],
  template: `
    <div
      appClickOutside
      (clickOutside)="onClickOutside()"
      class="inline-flex justify-between">
      <span
        class="text-nowrap text-center font-normal text-dune-500 sm:text-left">
        Rows per Page:
      </span>
      <div class="relative pr-12">
        <div class="dropdown-container group">
          <div
            class="page-size-option-list scrollbar--disabled"
            [ngClass]="{ invisible: !dropdownExpanded() }">
            <span
              *ngFor="let num of getNumbers(); let first = first"
              [ngClass]="{
                'page-size-option': true,
                'page-size-option--chosen': pageSize() === num,
                'page-size-option--not-chosen': pageSize() !== num,
              }"
              (click)="onPageSizeSelected(num)">
              {{ num }}
            </span>
          </div>
          <div
            class="not-selectable inline-flex w-full cursor-pointer pt-0.5"
            (click)="onPageSizeClicked()">
            <span class="text--bold pr-1.5 text-dune-500">
              {{ pageSize() }}
            </span>
            <fa-icon
              [ngClass]="{
                icon: true,
                'icon--clicked': dropdownExpanded(),
              }"
              [icon]="['fas', 'caret-down']"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class PaginationRowsPerPageComponent {
  /**
   * INPUTS
   * **/
  pageSize = input.required<number>();
  stepCount = input.required<number>();
  stepSize = input.required<number>();

  /**
   * OUTPUTS
   * **/
  updateSize = output<number>();

  dropdownExpanded = signal<boolean>(false);
  getNumbers = computed(() =>
    Array.from(
      { length: this.stepCount() },
      (_, i) => (i + 1) * this.stepSize()
    )
  );

  constructor(private library: FaIconLibrary) {
    this.library.addIcons(faCaretDown);
  }

  onClickOutside(): void {
    this.dropdownExpanded.set(false); // Hide the popup
  }

  onPageSizeSelected(pageSize: number): void {
    this.dropdownExpanded.set(false);
    this.updateSize.emit(pageSize);
  }

  onPageSizeClicked(): void {
    this.dropdownExpanded.set(!this.dropdownExpanded());
  }
}
