import { Component, computed, input, output } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatTooltip } from '@angular/material/tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'flk-pagination-page-stepper',
  imports: [FaIconComponent, MatTooltip, NgClass],
  template: `
    <div
      class="group relative inline-flex items-center rounded px-2 py-1.5"
      [matTooltip]="disabled() ? '' : tooltip()"
      (click)="onClick()">
      <span class="sr-only">{{ label() }}</span>
      <fa-icon [icon]="icon()" [ngClass]="getClass()" />
    </div>
  `,
})
export class PaginationPageStepperComponent {
  /** INPUTS **/

  /** Label to show **/
  label = input.required<string>();

  /** Tooltip to show **/
  tooltip = input.required<string>();

  /** Icon to show **/
  icon = input.required<IconProp>();

  /** Disable **/
  disabled = input<boolean>();

  /** OUTPUTS **/
  clicked = output<void>();

  getClass = computed(() => {
    return {
      ['cursor-pointer text-dune-500 dark:group-hover:text-greenish-500']:
        !this.disabled(),
      ['cursor-not-allowed text-dune-100 dark:text-dune-900']: this.disabled(),
    };
  });

  onClick(): void {
    if (!this.disabled()) {
      this.clicked.emit();
    }
  }
}
